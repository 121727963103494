import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksListInfo,
  SettingsPartsFragment,
} from '@generated/types';
import ShapesComponent from '@libs/ui/shared/shapes';
import SvgSubmenuSingleIcon from '@libs/ui/utils/Templates/Shapes/SubmenuSingleIcon';
import {
  getFontSizeClass,
  getFontWeightClassFromData,
  getHeadlineTagFromData,
  transformToHeadlineParams,
} from '@libs/ui/utils/Templates/style';
import { tinaField } from 'tinacms/dist/react';
import { SafeLink } from '../../utils/safe-link';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';

export default function ListInfo({
  data,
  site,
}: {
  data: PageBlocksListInfo;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  const HeadlineTag = getHeadlineTagFromData(data?.headline);
  const fontWeightClass = getFontWeightClassFromData(data?.headline);
  const bodyFontWeightClass = getFontWeightClassFromData(data?.body);
  const bodyParams = transformToHeadlineParams(data?.body);
  const bodyFontSizeClass = getFontSizeClass(bodyParams);
  const BodyTag = getHeadlineTagFromData(data?.body);

  return (
    <section
      className={`section-list-info flex items-center justify-center ${spacingClasses}`}
      {...getSpacingProps(data)}
      data-tina-field={tinaField(data)}
      id={data?.id || undefined}
    >
      <div className="contentGrid grid container border-t border-t-white pt-6">
        <div className="col-span-4 order-2 lg:order-1 lg:col-span-2 gridCol col-start-1 lg:mt-0 h-auto pt-[30px] lg:pt-0">
          {' '}
          {/* Added padding-top for mobile */}
          <BodyTag
            className={`${bodyFontWeightClass} ${bodyFontSizeClass}`}
            data-tina-field={tinaField(data.body, 'text')}
          >
            {data?.body?.text}
          </BodyTag>
          <div className="w-full flex mt-[30px] lg:mt-[60px] justify-between">
            <div className="button-outer">
              <SafeLink
                className="flex items-center"
                href={data?.link?.link ?? '#'}
                data-tina-field={tinaField(data.link, 'link')}
              >
                <SvgSubmenuSingleIcon className="mr-2" />
                <div
                  className="ml-[5px]"
                  data-tina-field={tinaField(data.link, 'buttonText')}
                >
                  {/* See Job Position */}
                  {data?.link?.buttonText}
                </div>
              </SafeLink>
            </div>

            <div data-tina-field={tinaField(data, 'shapeOptions')}>
              <ShapesComponent shapeOptions={data?.shapeOptions || {}} />
            </div>
          </div>
        </div>
        <div className="col-span-4 order-1 lg:order-2 lg:col-span-2 gridCol col-start-1 lg:col-start-3 flex justify-start lg:justify-end h-auto">
          <HeadlineTag
            className={fontWeightClass}
            data-tina-field={tinaField(data.headline, 'text')}
          >
            {data?.headline?.text}
          </HeadlineTag>
        </div>
      </div>
    </section>
  );
}

ListInfo.blockName = 'PageBlocksListInfo';
