import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksAnimationVennDiagram,
  SettingsPartsFragment,
} from '@generated/types';
import { tinaField } from 'tinacms/dist/react';
import { AnimationTextReusable } from '../AnimationText';
import './keyframes.css';

export default function AnimationVennDiagram(props: {
  data: PageBlocksAnimationVennDiagram;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  return (
    <AnimationTextReusable {...props}>
      <VennDiagram data={props?.data} />
    </AnimationTextReusable>
  );
}

const Circle = ({ className }: { className: string }) => (
  <div
    className={
      className +
      ` bg-[#1c1c1c] mix-blend-screen absolute rounded-full w-[60%] h-[60%] opacity-[100%]`
    }
  />
);

const TopCircle = ({ className }: { className?: string }) => (
  <Circle
    className={`
  left-[20%] animate-[topCircleAnim_8s_ease-in-out_infinite_alternate]
`}
  />
);

const LeftCircle = ({ className }: { className?: string }) => (
  <Circle
    className={`
  absolute left-0 top-[35.5%] animate-[leftCircleAnim_8s_ease-in-out_infinite_alternate]
`}
  />
);

const RightCircle = ({ className }: { className?: string }) => (
  <Circle
    className={`
absolute right-0 top-[35.5%] animate-[rightCircleAnim_8s_ease-in-out_infinite_alternate]
`}
  />
);

const TextCircle = ({
  className,
  children,
}: {
  className?: string;
  children: any;
}) => (
  <div
    className={
      className +
      ` absolute whitespace-normal animate-[textAnim_8s_ease-in-out_infinite_alternate]`
    }
  >
    {children}
  </div>
);

function VennDiagram({ data }: { data: PageBlocksAnimationVennDiagram }) {
  return (
    <div
      className={`min-w-[230px] flex flex-col text-start justify-start relative overflow-hidden`}
      style={{ flex: '1 1 100%' }}
    >
      <div className="object-contain w-full overflow-hidden aspect-square relative aspect-square">
        <TopCircle />
        <LeftCircle />
        <RightCircle />
        <img
          className={`h-[10%] w-[10%] top-[48.5%] left-[45%] absolute mix-blend-mode-screen`}
          src="/svg/OPT_logo.svg"
          alt="OPT Logo"
        />

        <TextCircle
          className="top-[18%] w-full text-center"
          data-tina-field={tinaField(data?.circleTexts, 'top')}
        >
          {data?.circleTexts?.top || 'Entry Top'}{' '}
        </TextCircle>
        <TextCircle
          className="top-[62%] left-[5%] w-[35%] text-center"
          data-tina-field={tinaField(data?.circleTexts, 'left')}
        >
          {data?.circleTexts?.left || 'Entry Left'}{' '}
        </TextCircle>
        <TextCircle
          className="top-[62%] right-[5%] w-[35%] text-center"
          data-tina-field={tinaField(data?.circleTexts, 'right')}
        >
          {data?.circleTexts?.right || 'Entry Right'}{' '}
        </TextCircle>
      </div>
    </div>
  );
}

AnimationVennDiagram.blockName = 'PageBlocksAnimationVennDiagram';
