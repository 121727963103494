'use client';
import { Site } from '@apps/site';
import { PageBlocksCategorySlider } from '@generated/types';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from '@libs/ui/shared/carousel';
import { Slider } from '@libs/ui/shared/slider';
import SvgComponent from '@libs/ui/utils/Templates/Shapes/headline_link_arrow_icon';
import {
  getFontWeightClass,
  getHeadlineTag,
  transformToHeadlineParams,
} from '@libs/ui/utils/Templates/style';
import { useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import SubmenuSingleIcon from '../../utils/Templates/Shapes/SubmenuSingleIcon';
import AnyMedia, { sizes } from '../../utils/any-media';
import { SafeLink } from '../../utils/safe-link';
import { useScroll } from '../../utils/scroll-context';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';
export default function CategorySlider({
  data,
  site,
}: {
  data: PageBlocksCategorySlider;
  site: Site;
}) {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }
    setCurrent(api.selectedScrollSnap() + 1);
    api.on('select', () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  const HeadlineTag = getHeadlineTag(transformToHeadlineParams(data?.headline));
  const FontWeightClass = getFontWeightClass(
    transformToHeadlineParams(data?.headline),
  );

  const router = useRouter();

  const scrollY = useScroll();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const end = ref.current?.offsetTop || 0;
    const start = end - window?.innerHeight + (ref.current?.offsetHeight || 0);
    const progress = (start - scrollY) / (start - end);
    const cappedProgress = Math.min(Math.max(progress, 0), 1);
    const index = Math.floor((data?.entries?.length || 1) * cappedProgress);
    api?.scrollTo(index);
  }, [scrollY, api, data?.entries?.length]);

  return (
    <section
      className={`section-category-slider w-full flex items-center justify-center ${spacingClasses}`}
      {...getSpacingProps(data)}
      data-tina-field={tinaField(data)}
      id={data?.id || undefined}
    >
      <div className="container w-full" ref={ref}>
        {/* Headline Section with Arrow */}
        <div className="w-full text-left mb-10 flex items-center">
          <HeadlineTag
            className={`${FontWeightClass}`}
            data-tina-field={tinaField(data, 'headline')}
          >
            {data?.headline?.text}
          </HeadlineTag>
          <div className="transform -rotate-90 ml-2 w-6 h-6 !-z-10">
            <SvgComponent />
          </div>
        </div>
        {/* Slider Grid Section */}
        <div>
          <Carousel
            className="w-full [&>div]:!overflow-visible"
            onClick={(e) => {
              const entries = data?.entries;
              const isMobile = ((e.view as any)?.innerWidth || 0) <= 768;
              const padding = isMobile ? 32 : 84;
              const screenWidth = (e.view as any)?.innerWidth
                ? (e.view as any)?.innerWidth - 2 * padding
                : 0;
              const scroll = api?.scrollProgress() || 0;
              const columnWidth = screenWidth / 4; // 4 columns on both mobile and desktop
              const entryColumns = isMobile ? 3 : 2;
              const entryWidth = columnWidth * entryColumns;
              const totalColumns = isMobile
                ? (entries?.length || 0) * 3
                : (entries?.length || 0) * 2 + 1;
              const totalWidth = totalColumns * columnWidth;
              const clickColumnIndex = (e.clientX - padding) / columnWidth; // Column where the click occurred
              const columnsBehind = (totalColumns - 4) * scroll;
              const absoluteColumnClicked = clickColumnIndex + columnsBehind;
              const entryClicked = isMobile
                ? Math.floor(absoluteColumnClicked / entryColumns)
                : Math.floor((absoluteColumnClicked - 1) / entryColumns);
              const entry = entries?.[entryClicked];
              console.log('Clicked entry:', entry?.entryHeadline);
              entry?.entryLinkTarget && router.push(entry?.entryLinkTarget);
            }}
            setApi={setApi}
          >
            <CarouselContent className="-ml-4 xl:-ml-custom-xl-gap hd:-ml-custom-hd-gap 2k:-ml-custom-2k-gap lg:pl-[25%] relative -z-50">
              {data?.entries?.map((entry, index) => (
                <CarouselItem
                  key={index}
                  className="pl-4 xl:pl-custom-xl-gap hd:pl-custom-hd-gap 2k:pl-custom-2k-gap basis-3/4 lg:basis-2/3"
                >
                  <div
                    className={`flex flex-col lg:flex-row pb-4 gap-x-4 xl:gap-x-custom-xl-gap hd:gap-x-custom-hd-gap 2k:gap-x-custom-2k-gap min-h-full`}
                  >
                    <div className={`lg:w-1/2 relative`}>
                      <AnyMedia
                        source={entry?.image ?? ''}
                        alt={entry?.mediaAlt ?? entry?.entryHeadline ?? ''}
                        data-tina-field={tinaField(entry, 'image')}
                        data-shadow={data?.boxShadow}
                        sizes={sizes({ default: '75vw', lg: '20vw' })}
                        className={`data-[shadow=true]:box-shadow-big aspect-square relative overflow-hidden`}
                      />
                    </div>
                    <div className="flex flex-1 flex-col justify-between overflow-hidden lg:w-1/2 !-z-10">
                      <h4
                        data-tina-field={tinaField(entry, 'entryHeadline')}
                        className="lg:pb-5 -lg:text-lg -lg:mt-2 font-bold lg:font-light"
                      >
                        {entry?.entryHeadline}
                      </h4>
                      <div
                        data-tina-field={tinaField(entry, 'entryBody')}
                        className="leading-[32px] flex-1 body-text lg:font-light !-z-10"
                      >
                        {entry?.entryBody
                          ?.split('\n')
                          .map((t, i) =>
                            t ? <p key={i}>{t}</p> : <br key={i} />,
                          ) || ``}
                      </div>
                      {entry?.entryLinkTarget && (
                        <div className="flex items-center !-z-10">
                          <SubmenuSingleIcon
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              color: entry.entryLinkColor ?? 'inherit',
                              marginTop: '20px',
                            }}
                          />
                          <SafeLink
                            href={entry.entryLinkTarget}
                            style={{
                              color: entry.entryLinkColor ?? '',
                              marginTop: '20px',
                            }}
                            data-tina-field={tinaField(
                              entry,
                              'entryLinkTarget',
                            )}
                          >
                            {entry.entryLinkName}
                          </SafeLink>
                        </div>
                      )}
                    </div>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </div>

        {/* Slider Control Destkop */}
        <div className="mt-10 mb-10 w-full container contentGrid grid ">
          <div className="col-start-2 col-span-3 lg:col-start-3 lg:-mr-[75px] lg:col-span-2 lg:-ml-4 xl:-ml-custom-xl-gap hd:-ml-custom-hd-gap 2k:-ml-custom-2k-gap">
            <Slider
              value={[api ? api?.selectedScrollSnap() : 0]}
              onValueChange={(value) => {
                const index = value?.[0];
                api?.scrollTo(index);
              }}
              min={0}
              max={api ? api.scrollSnapList().length - 1 : undefined}
              className="mt-4 w-full lg:-ml-[75px] "
              aria-label="Slider element to navigate between slides"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

CategorySlider.blockName = 'PageBlocksCategorySlider';
