import React from 'react';

interface SvgComponentProps extends React.SVGProps<SVGSVGElement> {}

const SvgComponent: React.FC<SvgComponentProps> = ({ style, ...props }) => (
  <svg
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    {...props}
  >
    <path
      d="M19.5992 0.370117H17.5992V35.4101H19.5992V0.370117Z"
      className={`fill-primary`}
    />
    <path
      d="M18.5992 37.0001L0.199219 18.5901L1.60922 17.1801L18.5992 34.1701L35.5892 17.1801L36.9992 18.5901L18.5992 37.0001Z"
      className={`fill-primary`}
    />
  </svg>
);

export default SvgComponent;
