import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksAnimationFactory,
  SettingsPartsFragment,
} from '@generated/types';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';
import bgImg from './technologystack_backg3.jpg';

export default function AnimationFactory({
  data,
  global,
  site,
}: {
  data: PageBlocksAnimationFactory;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  const [inView, setInView] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
        }
      },
      { threshold: 0.1 }, // Adjust the threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <section
      ref={containerRef}
      className={`section-animation-factory w-screen flex flex-col items-center justify-center !mb-0 relative bg-center bg-cover p-0 content-start box-border flex-wrap overflow-hidden ${spacingClasses}`}
      data-tina-field={tinaField(data)}
      {...getSpacingProps(data)}
      id={data?.id || undefined}
    >
      {data?.headline && (
        <>
          <h2>
            <div>{data?.headline?.text}</div>
          </h2>
        </>
      )}

      <div className="relative bg-primary-foreground h-full">
        <Image
          src={bgImg}
          sizes="100vw"
          alt="Technology Stack - How do we Operate"
          className="relative mix-blend-multiply w-full lg:h-full [transform:translate3d(0,0,0)]"
        />
        {Array.from(Array(8).keys()).map((d: any, i: number) => (
          <img
            src={'/svg/0' + (i + 1) + '.svg'}
            alt={'Technology Stack - slide ' + (i + 1)}
            key={'child' + i}
            className={`absolute top-0 left-0 w-full lg:h-full transition-opacity duration-500 ease-in-out ${
              inView ? 'opacity-100' : 'opacity-0'
            }`}
            style={{
              transitionDelay: `${(i + 1) * 0.55}s`,
            }}
          />
        ))}
      </div>
    </section>
  );
}

AnimationFactory.blockName = 'PageBlocksAnimationFactory';
