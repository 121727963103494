'use client';

import React, { ReactNode, useEffect, useState } from 'react';
import { Controller, FieldValues, Path, useForm } from 'react-hook-form';

import { ArrowRight } from '@libs/ui/utils/Templates/Shapes';
import { FieldGroup, FormProps } from './types';

import { PageBlocksContactForm } from '@generated/types';
import { Shape } from '@libs/ui/utils/enums';
import { ChevronRight, TriangleAlert } from 'lucide-react';
import { BiInfoSquare } from 'react-icons/bi';
import { tinaField } from 'tinacms/dist/react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../shared/select';
import { SingleShapeComponent } from '../../../shared/shapes';
import styles from './style.module.css';
const parseRichTextToCustomName = (
  group: FieldGroup,
  customStyles?: React.CSSProperties,
  customClassName?: string,
) => {
  if ('richText' in group) {
    const match = group.richText.match(/<(h\d|p|span)[^>]*>(.*?)<\/\1>/);
    if (match) {
      const tag = match[1];
      const styles =
        tag === 'p' || tag === 'span'
          ? { fontSize: '14px' }
          : { fontWeight: 'UltraLight' };
      return (
        <div
          dangerouslySetInnerHTML={{ __html: group.richText }}
          style={{ ...styles, ...customStyles }}
          className={customClassName}
        />
      );
    }
  }
};

export default function HubSpotForm<T extends FieldValues>({
  fieldGroups,
  submitButtonText,
  onSubmit,
  isLoading,
  isSubmitting,
  isSubmitted,
  loader,
  skeletonHeight = 400,
  successMessage = 'The form has been submitted successfully.',
  errorMessage,
  formStyles = {},
  formClassName = '',
  fieldGroupStyles = {},
  fieldGroupClassName = '',
  fieldContolStyles = {},
  fieldContolClassName = '',
  labelStyles = {},
  labelClassName = '',
  fieldStyles = {},
  fieldClassName = '',
  successMessageStyles = {},
  successMessageClassName = '',
  errorMessageStyles = {},
  errorMessageClassName = '',
  buttonStyles = {},
  buttonClassName = '',
  data,
  children,
}: FormProps<T> & { data: PageBlocksContactForm; children: ReactNode }) {
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [isTouched, setIsTouched] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setFormSubmitted(isSubmitted || false);
  }, [isSubmitted]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    trigger,
  } = useForm<T>();

  const handleBlurValidation = async (name: string, onBlur?: () => void) => {
    await trigger(name as Path<T>); // Trigger validation for the specified input field
    if (onBlur) onBlur(); // Call the original onBlur handler if provided
    setIsTouched((prev) => ({ ...prev, [name]: true }));
  };

  const handleChangeValidation = (name: string) => {
    setIsTouched((prev) => ({ ...prev, [name]: true }));
  };

  const onInvalidSubmit = async () => {
    // Mark all required fields as touched
    const newTouched: Record<string, boolean> = {};
    fieldGroups.forEach((group) => {
      'fields' in group &&
        group.fields?.forEach((field) => {
          if (field.required) {
            newTouched[field.name] = true;
          }
        });
    });
    setIsTouched((prev) => ({ ...prev, ...newTouched }));
  };

  if (isLoading) {
    return loader ? (
      <>{loader}</>
    ) : (
      <div
        className={`${styles['rhhf-skeleton']}`}
        style={{ height: skeletonHeight }}
      />
    );
  }

  const handleKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)(e);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}
      className={`rhhf-form ${formClassName}`.trim()}
      style={formStyles}
      data-tina-field={tinaField(data, 'shapesOverrides')}
      onKeyUp={(e) => handleKeyUp(e)}
    >
      {children}
      {fieldGroups?.map((group, groupIndex) => {
        const absoluteElementIndex = fieldGroups
          ?.slice(0, groupIndex)
          .reduce((acc, curr) => ('fields' in curr ? acc + 1 : acc), 0);
        const shape: Shape =
          (data?.shapesOverrides?.[absoluteElementIndex] as Shape) ||
          Shape.Empty;

        return (
          <div
            key={groupIndex}
            style={fieldGroupStyles}
            className="flex flex-col lg:flex-row"
            // className={`rhhf-group ${styles['rhhf-group']} ${fieldGroupClassName}`.trim()}
          >
            <div className="relative w-0 ml-[100%] lg:ml-0">
              <div className="absolute !z-20 right-0 lg:w-0 md:-left-4 xl:-left-custom-xl-gap hd:-left-custom-hd-gap 2k:-left-custom-2k-gap overflow-visible md:-translate-x-[15px] lg:-translate-x-[25px] flex-shrink-0 flex-grow-0  pt-5 lg:pt-6">
                <SingleShapeComponent shape={shape} />
              </div>
            </div>
            <div className="flex flex-1 space-y-0.5 lg:gap-4  lg:space-x-0 flex-col lg:flex-row">
              {'fields' in group
                ? group?.fields?.map((field, fieldIndex) => {
                    const commonProps = {
                      style: fieldStyles,
                      id: field?.name,
                      hidden: field?.hidden,
                      placeholder: field.label
                        ? `${field.label}${field?.required ? ' *' : ''}`
                        : field?.placeholder,
                      defaultValue:
                        field.defaultValue !== undefined
                          ? field.defaultValue
                          : field.defaultValues?.length
                            ? field.defaultValues[0]
                            : '',
                      className: [
                        'rhhf-field',
                        styles['rhhf-field'],
                        fieldClassName,
                        isTouched[field?.name] &&
                          (errors[field?.name] ? 'invalid' : 'valid'),
                        isTouched[field?.name] &&
                          (errors[field?.name]
                            ? styles['rhhf-field-invalid']
                            : {}),
                      ]
                        .filter(Boolean)
                        .join(' ')
                        .trim(),
                      ...register(field?.name as Path<T>, {
                        required: field?.required
                          ? `This field is required`
                          : false,
                        pattern:
                          field?.fieldType === 'email'
                            ? {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: `Valid email is required`,
                              }
                            : field?.fieldType === 'phone'
                              ? {
                                  value: /^[0-9]{10}$/,
                                  message: `Valid phone number is required`,
                                }
                              : undefined,
                      }),
                    };

                    return (
                      <div
                        className="flex flex-row justify-center items-center space-x-2 flex-1"
                        key={fieldIndex}
                      >
                        <div
                          key={fieldIndex}
                          style={fieldContolStyles}
                          className={`rhhf-control !static ${styles['rhhf-control']} ${
                            field.hidden ? styles['rhhf-control-hidden'] : ''
                          } ${fieldContolClassName}`.trim()}
                        >
                          {/* {!field?.hidden &&
                          field?.fieldType !== 'single_checkbox' && (
                            <label
                              htmlFor={field?.name}
                              className={`rhhf-label ${styles['rhhf-label']} ${labelClassName}`.trim()}
                              style={labelStyles}
                            >
                              {field?.label}
                              {field?.required && (
                                <span
                                  style={{ color: 'red', marginLeft: '2px' }}
                                >
                                  *
                                </span>
                              )}
                            </label>
                          )} */}
                          <Controller
                            name={field?.name as Path<T>}
                            control={control}
                            render={(renderProps) => {
                              switch (field.fieldType) {
                                case 'single_line_text':
                                case 'email':
                                case 'phone':
                                case 'number':
                                  return (
                                    <input
                                      type={
                                        field.fieldType === 'single_line_text'
                                          ? 'text'
                                          : field.fieldType
                                      }
                                      {...commonProps}
                                      onChange={(e) => {
                                        handleChangeValidation(field.name);
                                        renderProps.field.onChange(e);
                                      }}
                                      onBlur={() =>
                                        handleBlurValidation(
                                          field.name,
                                          renderProps.field.onBlur,
                                        )
                                      }
                                      className="!bg-none !bg-transparent text-inherit placeholder:italic placeholder:font-[100] text-color placeholder-text_color shadow-none border-b-2 border-shade20 resize-none !rounded-none focus:outline-none focus:ring-0 py-4 lg:py-6"
                                    />
                                  );
                                case 'multi_line_text':
                                  return (
                                    <textarea
                                      {...commonProps}
                                      onChange={(e) => {
                                        handleChangeValidation(field.name);
                                        renderProps.field.onChange(e);
                                      }}
                                      onBlur={() =>
                                        handleBlurValidation(
                                          field?.name,
                                          renderProps.field.onBlur,
                                        )
                                      }
                                      className="pt-16px !bg-none !bg-transparent text-inherit placeholder:italic placeholder:font-[100] text-text_color placeholder-text_color shadow-none border-b-2 border-shade20 resize-none !rounded-none focus:outline-none focus:ring-0 py-8 lg:py-12"
                                    />
                                  );
                                case 'dropdown':
                                  return (
                                    <div className="border-b-2 border-shade20 w-full">
                                      <Select
                                        onValueChange={
                                          renderProps.field.onChange
                                        }
                                        defaultValue={renderProps.field.value}
                                      >
                                        <SelectTrigger
                                          data-placeholder={
                                            !renderProps.field.value
                                          }
                                          className="focus:ring-0 focus:outline-none w-[calc(100%-2rem)] lg:w-full h-[3.5rem] lg:h-[4.7rem] text-start text-inherit data-[placeholder=true]:!italic data-[placeholder=true]:!font-[400] data-[placeholder=true]:!text-text_color py-6 lg:py-10 text-text_color rounded-none"
                                        >
                                          <SelectValue
                                            placeholder={
                                              field.label
                                                ? `${field.label}${field?.required ? ' *' : ''}`
                                                : field?.placeholder || 'Select'
                                            }
                                            className="!bg-none border-none !shadow-none  active:!shadow-none  selection:!shadow-none focus:!shadow-none"
                                          />
                                        </SelectTrigger>
                                        <SelectContent className="bg-background rounded-none w-[calc(100%+2rem)] lg:w-full">
                                          {field?.options?.map(
                                            (option, idx) => (
                                              <SelectItem
                                                key={idx}
                                                value={option?.value}
                                                className="py-4 lg:py-6 text-inherit hover:bg-primary/10"
                                              >
                                                {option.label}
                                              </SelectItem>
                                            ),
                                          )}
                                        </SelectContent>
                                      </Select>
                                    </div>
                                  );
                                case 'single_checkbox':
                                  return (
                                    <>
                                      <label
                                        htmlFor={field.name}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'flex-start',
                                          gap: 10,
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            field.defaultValue === 'true'
                                          }
                                          {...commonProps}
                                          onChange={(e) => {
                                            handleChangeValidation(field.name);
                                            renderProps.field.onChange(e);
                                          }}
                                          onBlur={() =>
                                            handleBlurValidation(
                                              field?.name,
                                              renderProps.field.onBlur,
                                            )
                                          }
                                        />
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: field?.label,
                                          }}
                                        />
                                        {field?.required && (
                                          <span
                                            style={{
                                              color: 'red',
                                              marginLeft: '3px',
                                            }}
                                          >
                                            *
                                          </span>
                                        )}
                                      </label>
                                    </>
                                  );
                                case 'radio':
                                  return (
                                    <div
                                      className={`rhhf-radio-group ${styles['rhhf-radio-group']}`}
                                    >
                                      {field?.options?.map((opt, idx) => {
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        const { defaultValue, ...rest } =
                                          commonProps;
                                        return (
                                          <label key={`opt${idx}`}>
                                            <input
                                              type="radio"
                                              {...rest}
                                              value={opt?.value}
                                              defaultChecked={field?.defaultValues?.includes(
                                                opt?.value,
                                              )}
                                            />
                                            {opt?.label}
                                          </label>
                                        );
                                      })}
                                    </div>
                                  );
                                case 'multiple_checkboxes':
                                  return (
                                    <div
                                      className={`rhhf-checkbox-group ${styles['rhhf-checkbox-group']}`}
                                    >
                                      {field?.options?.map((opt, idx) => {
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        const { defaultValue, ...rest } =
                                          commonProps;
                                        return (
                                          <label key={`opt${idx}`}>
                                            <input
                                              type="checkbox"
                                              {...rest}
                                              value={opt?.value}
                                              defaultChecked={field?.defaultValues?.includes(
                                                opt?.value,
                                              )}
                                            />
                                            {opt?.label}
                                          </label>
                                        );
                                      })}
                                    </div>
                                  );
                                default:
                                  return <></>;
                              }
                            }}
                          />
                          {!field?.hidden && (
                            <p
                              style={errorMessageStyles}
                              className="text-red-500/50 text-xs translate-y-1 lg:h-0 overflow-visible -z-20"
                            >
                              {errors[field?.name] &&
                              field?.fieldType === 'single_checkbox'
                                ? 'This field is required'
                                : (errors[field?.name]?.message as ReactNode)}
                            </p>
                          )}
                        </div>
                      </div>
                    );
                  })
                : parseRichTextToCustomName(group)}
            </div>
          </div>
        );
      })}
      {Object.keys(errors).length === 0 && errorMessage && (
        <p
          style={errorMessageStyles}
          className="bg-shade20 p-4 lg:p-6 flex lg:mb-4 mt-10 text-red-700"
        >
          <TriangleAlert className="mr-2 text-lg text-xs h-4" />
          {errorMessage}
        </p>
      )}
      {isFormSubmitted && (
        <p
          style={successMessageStyles}
          className="bg-shade20 p-4 lg:p-6 flex lg:mb-4 mt-10"
        >
          <BiInfoSquare className="mr-2 text-lg" />
          {successMessage}
        </p>
      )}
      {submitButtonText && (
        <div className="mt-10">
          <div className="relative w-0">
            <div className="hidden absolute pointer-events-none top-3 w-0 lg:flex overflow-visible -left-4 xl:-left-custom-xl-gap hd:-left-custom-hd-gap 2k:-left-custom-2k-gap -translate-x-[16px] md:-translate-x-[15px] lg:-translate-x-[25px] flex-shrink-0 flex-grow-0 pt-4">
              <SingleShapeComponent shape={Shape.Left} />
            </div>
          </div>
          <button
            disabled={isSubmitting}
            type="button"
            onClick={handleSubmit(onSubmit)}
            style={buttonStyles}
            className="h-10 pr-14 lg:pr-0 flex items-center justify-between text-start lg:h-20 lg:text-center bg-shade20 w-full lg:w-[calc(100%+10rem)] lg:-ml-[10rem] lg:rounded-l-full"
          >
            <span className="w-full text-start lg:ml-40">
              {submitButtonText}
            </span>
            <ChevronRight className="h-8 w-12  hidden lg:block" />
            <span className="-mt-[4px] lg:hidden">
              <ArrowRight width="w-[24px]" />
            </span>
          </button>
          <div className="relative  w-0 ml-[100%] -mt-10">
            <div className="lg:hidden absolute pointer-events-none right-0 top-3 flex">
              <SingleShapeComponent shape={Shape.Circle} />
              <SingleShapeComponent shape={Shape.Right} />
              <SingleShapeComponent shape={Shape.Square} />
            </div>
          </div>
        </div>
      )}
    </form>
  );
}
