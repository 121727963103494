import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksContactForm,
  SettingsPartsFragment,
} from '@generated/types';
import { useEffect, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';
import { getHubSpotForm } from './actions';
import { HubSpotFormResponse } from './types';

import ContactFormBase from './ContactFormBase';

export default function ContactForm({
  data,
  title,
  path,
  forms,
  site,
}: {
  data: PageBlocksContactForm;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  forms?: Record<string, HubSpotFormResponse>;
  site: Site;
  title?: string;
  path?: string;
}) {
  const [formData, setFormData] = useState<any | null>(null);

  useEffect(() => {
    (async () => {
      if (data.contactFormId) {
        const formData = await getHubSpotForm(data.contactFormId);
        setFormData(formData);
      } else {
        setFormData(null);
      }
    })();
  }, [data.contactFormId]);

  const form =
    (data?.contactFormId && forms?.[data?.contactFormId]) || formData;

  return (
    <section
      className={`section-contact-form container ${spacingClasses}`}
      data-tina-field={tinaField(data)}
      {...getSpacingProps(data)}
      id={data?.id || undefined}
    >
      {form ? (
        <ContactFormBase
          {...form}
          {...{ title, path, site }}
          contactFormId={data.contactFormId}
          shapesOverrides={data?.shapesOverrides}
          data={data}
        />
      ) : data?.contactFormId ? (
        <div>Loading...</div>
      ) : (
        <></>
      )}
      {!data?.contactFormId && (
        <div data-tina-field={tinaField(data, 'contactFormId')}>
          Contact form ID is not configured
        </div>
      )}
    </section>
  );
}
ContactForm.blockName = 'PageBlocksContactForm';
