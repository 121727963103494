import { Site } from '@apps/site';
import { PageBlocksMediumTeaserText } from '@generated/types';
import { FontSize, FontWeight } from '@libs/ui/utils/enums';
import {
  getFontSizeClass,
  getFontWeightClass,
  getHeadlineTag,
} from '@libs/ui/utils/Templates/style';
import { tinaField } from 'tinacms/dist/react';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';

export default function MediumTeaserText({
  data,
  site,
}: {
  data: PageBlocksMediumTeaserText;
  site: Site;
}) {
  const Tag = getHeadlineTag({ fontSize: data?.body?.fontSize as FontSize });
  const fontSizeClass = getFontSizeClass({
    fontSize: data?.body?.fontSize as FontSize,
  });
  const fontWeightClass = getFontWeightClass({
    fontWeight: data?.body?.fontWeight as FontWeight,
  });
  const isTextRight = data.variant === 'Text Right';

  return (
    <section
      className={`section-medium-teaser container contentGrid grid flex items-center justify-center ${spacingClasses}`}
      {...getSpacingProps(data)}
      data-tina-field={tinaField(data)}
      id={data?.id || undefined}
    >
      <div
        data-tina-field={tinaField(data, 'body')}
        className={`
          col-span-4 lg:col-span-2 ${isTextRight ? 'lg:col-start-3 col-start-3' : 'lg:col-start-1 col-start-1'}
          w-full rounded ${fontWeightClass}`}
      >
        <Tag
          className={`${fontSizeClass}`}
          data-tina-field={tinaField(data.body, 'text')}
        >
          {data?.body?.text}
        </Tag>
      </div>
    </section>
  );
}

MediumTeaserText.blockName = 'PageBlocksMediumTeaserText';
