import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksThreeColImageOneColText,
  SettingsPartsFragment,
} from '@generated/types';
import { tinaField } from 'tinacms/dist/react';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';

export default function ThreeColImageOneColText({
  data,
  global,
  settings,
  site,
}: {
  data: PageBlocksThreeColImageOneColText;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  return (
    <section
      className={`section-three-col-image-one-col-text w-full h-20 border rounded p-4 flex items-center justify-center ${spacingClasses}`}
      {...getSpacingProps(data)}
      data-tina-field={tinaField(data)}
      id={data?.id || undefined}
    >
      ThreeColImageOneColText
    </section>
  );
}
ThreeColImageOneColText.blockName = 'PageBlocksThreeColImageOneColText';
