import { Site } from '@apps/site';
import { PageBlocksAccordion } from '@generated/types';
import {
  Accordion as AccordionBase,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@libs/ui/shared/accordion';
import { SingleShapeComponent } from '@libs/ui/shared/shapes';
import { Shape } from '@libs/ui/utils/enums';
import {
  getFontWeightClassFromData,
  getHeadlineTagFromData,
} from '@libs/ui/utils/Templates/style';
import { tinaField } from 'tinacms/dist/react';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';

export default function Accordion({
  data,
  site,
}: {
  data: PageBlocksAccordion;
  site: Site;
}) {
  const HeadlineTag = getHeadlineTagFromData(data?.headline);
  const fontWeightClass = getFontWeightClassFromData(data?.headline);

  return (
    <section
      className={`section-accordion w-full text-sm container grid contentGrid rounded p-4 ${spacingClasses}`}
      data-tina-field={tinaField(data)}
      {...getSpacingProps(data)}
      id={data?.id || undefined}
    >
      <AccordionBase
        type="single"
        collapsible
        className={`col-span-4 order-2 lg:order-1 ${data?.headline?.text ? 'lg:col-span-2' : ''}`}
      >
        {data?.entries &&
          data.entries.length > 0 &&
          data.entries.map(
            (entry, index) =>
              entry?.entryHeadline &&
              entry?.entryBody && (
                <AccordionItem
                  value={`item-${index}`}
                  key={index}
                  className="mb-4 body-text"
                >
                  {/* Entry Headline */}

                  <AccordionTrigger
                    className="headingfont text-start text-3xl"
                    data-tina-field={tinaField(entry, 'entryHeadline')}
                    shapes={
                      entry?.shape && (
                        <div
                          data-tina-field={tinaField(entry, 'shape')}
                          className="ml-8 !z-20"
                        >
                          <SingleShapeComponent shape={entry.shape as Shape} />
                        </div>
                      )
                    }
                  >
                    {entry.entryHeadline}
                  </AccordionTrigger>

                  {/* Entry Body */}

                  <AccordionContent
                    className="mt-2 text-2xl"
                    data-tina-field={tinaField(entry, 'entryBody')}
                  >
                    {entry.entryBody}
                  </AccordionContent>
                </AccordionItem>
              ),
          )}
      </AccordionBase>
      {data?.headline?.text && (
        <HeadlineTag
          className={`col-span-4 lg:col-span-2 order-1 lg:order-2 ${fontWeightClass} flex mb-8 justify-start lg:justify-end items-start`}
          data-tina-field={tinaField(data, 'headline')}
        >
          {data.headline.text}
        </HeadlineTag>
      )}
    </section>
  );
}

Accordion.blockName = 'PageBlocksAccordion';
