import { i18n } from '@apps/i18n-config';
import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksNewsListBlogPosts,
  SettingsPartsFragment,
} from '@generated/types';
import { Shape } from '@libs/ui/utils/enums';
import { ArrowRight } from '@libs/ui/utils/Templates/Shapes';
import { Fragment, useEffect, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import { TrimmedArticle } from '../../Page';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../shared/accordion';
import ShapesComponent, { SingleShapeComponent } from '../../shared/shapes';
import { getLocaleAndCleanSlug } from '../../utils';
import { SafeLink } from '../../utils/safe-link';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';
import {
  getFontWeightClass,
  getHeadlineTagFromData,
  transformToHeadlineParams,
} from '../../utils/Templates/style';

const filterArticles = (
  category: string | null,
  articles: TrimmedArticle[],
  postsToShow?: number | null,
) => {
  return articles
    ?.filter((a) => !category || a.categories?.includes(category))
    .sort((a, b) => {
      return (
        new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
      );
    })
    .filter((_, index) => !postsToShow || index < Number(postsToShow));
};

export default function NewsListBlogPosts({
  data,
  global,
  settings,
  site,
  articles,
}: {
  data: PageBlocksNewsListBlogPosts;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
  articles?: TrimmedArticle[] | null;
}) {
  const [category, setCategory] = useState<string | null>(null);
  const defaultArticles =
    articles && filterArticles(category, articles, data?.postsToShow);
  const [articleData, setArticleData] = useState<
    TrimmedArticle[] | undefined | null
  >(defaultArticles);

  useEffect(() => {
    (async () => {
      if (data.categories) {
        // first and last day of the year
        try {
          const res =
            articles && filterArticles(category, articles, data?.postsToShow);
          setArticleData(res);
        } catch (e) {
          console.log(e);
        }
      } else {
        setArticleData(null);
      }
    })();
  }, [
    data.categories,
    category,
    data.postsToShow,
    data.showSpecificNews,
    data.specificNewsEntries,
  ]);

  return (
    <section
      className={`section-news-cards-blog-posts container section-newslist-blog ${spacingClasses}`}
      {...getSpacingProps(data)}
      data-tina-field={tinaField(data)}
      id={data?.id || undefined}
    >
      <div className="flex flex-col-reverse lg:contentGrid">
        <div className="col-span-2 lg:col-span-3 flex flex-col">
          {(data.showSpecificNews
            ? data?.specificNewsEntries
                ?.map((v) => v?.article)
                ?.map((v) => ({ ...v, relativePath: v?._sys?.relativePath }))
            : articleData
          )?.map((article, index) => {
            // get date in the 23/11/01 format
            const date =
              article?.createdAt &&
              new Date(article?.createdAt).toLocaleDateString('en-GB', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
              });
            const params = getLocaleAndCleanSlug(
              article?.relativePath
                ?.replace('.mdx', '')
                .replace('.md', '')
                ?.split('/') || [],
              i18n?.locales || [],
            );
            const shape =
              (data?.shapesOverrides?.[index] as Shape) || Shape.Empty;
            return (
              <Fragment key={index}>
                <div
                  key={article?.id}
                  className="lg:contentGrid !grid-cols-3 gap-10"
                >
                  <div className="mt-6 col-span-1">
                    {[...(date?.split('/') || [])].reverse()?.join(' ')}
                  </div>
                  <div className="mt-3 lg:mt-6 col-span-1 capitalize flex justify-between">
                    <span>{article?.categories?.join(', ')}</span>
                    <span className="hidden lg:block relative">
                      <SingleShapeComponent shape={shape} />
                    </span>
                    <span className="lg:hidden">
                      <ShapesComponent
                        shapeOptions={{
                          left: Shape.Circle,
                          middle: Shape.Right,
                          right: Shape.Square,
                        }}
                      />
                    </span>
                  </div>
                  <SafeLink
                    href={`${params?.locale ? `/${params.locale}` : ''}/articles/${params?.slug?.join('/')}`}
                    className="mt-6 hidden lg:flex col-span-1 justify-between cursor-pointer"
                    aria-label={`Read more on: ${article.title}`}
                  >
                    Read More
                    <span className="ml-1.5 -mt-0.5">
                      <ArrowRight width="w-[24px]" />
                    </span>
                  </SafeLink>
                </div>
                <div className="w-full text-ellipsis overflow-hidden static whitespace-nowrap mt-6 lg:pb-4 lg:border-b border-primary/50">
                  {article?.title}
                </div>
                <SafeLink
                  href={`${params?.locale ? `/${params.locale}` : ''}/articles/${params?.slug?.join('/')}`}
                  aria-label={`Read more on: ${article.title}`}
                  className="font-light mt-6 pb-6 flex justify-start lg:hidden col-span-1 lg:justify-between cursor-pointer border-b lg:border-none border-primary/50"
                >
                  Read more
                  <span className="ml-1.5 -mt-0.5">
                    <ArrowRight width="w-[24px]" />
                  </span>
                </SafeLink>
              </Fragment>
            );
          })}
        </div>

        <FiltersColumn
          data={data}
          global={global}
          settings={settings}
          {...{ category, setCategory }}
        />
      </div>
    </section>
  );
}

const FiltersColumn = (props: {
  data: PageBlocksNewsListBlogPosts;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  category: string | null;
  setCategory: (category: string) => void;
}) => {
  const { data } = props;
  const headlineParams = transformToHeadlineParams(data?.headline);
  const HeadlineTag = getHeadlineTagFromData(headlineParams);
  const fontWeightClass = getFontWeightClass(headlineParams);

  return (
    <>
      <div className="hidden lg:flex flex-col col-span-2 lg:col-span-1 space-y-4 mt-4">
        {/* Headline */}
        {data?.headline?.text && (
          <HeadlineTag
            className={fontWeightClass}
            data-tina-field={tinaField(data, 'headline')}
          >
            {data.headline.text}
          </HeadlineTag>
        )}
        {data.showFilter && !data.showSpecificNews && <Categories {...props} />}
      </div>
      <div className="lg:hidden flex-col col-span-2 lg:col-span-1 space-y-4 mt-4">
        {data?.headline?.text && (
          <HeadlineTag
            className={fontWeightClass}
            data-tina-field={tinaField(data, 'headline')}
          >
            {data.headline.text}
          </HeadlineTag>
        )}
        {data.showFilter && !data.showSpecificNews && (
          <Accordion type="single" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger className="body-text">Filter</AccordionTrigger>
              <AccordionContent>
                <div className="space-y-4 -mt-4">
                  <Categories {...props} />
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )}
      </div>
    </>
  );
};

const Categories = ({
  data,
  category,
  setCategory,
}: {
  data: PageBlocksNewsListBlogPosts;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  category: string | null;
  setCategory: (category: string) => void;
}) => {
  return (
    <>
      {/* Categories */}
      <span className="h-8 w-full"></span>
      {data?.categories?.map((c, index) => (
        <Fragment key={index}>
          <div
            key={index}
            data-tina-field={tinaField(data, 'categories', index)}
            data-selected={category === c}
            className="capitalize data-[selected=true]:underline data-[selected=true]:text-primary underline-offset-8 cursor-pointer text-primary/90 hover:text-primary"
            onClick={() => c && setCategory(c)}
          >
            .{` `}
            <span>{c}</span>
          </div>
        </Fragment>
      ))}
    </>
  );
};

NewsListBlogPosts.blockName = 'PageBlocksNewsListBlogPosts';
