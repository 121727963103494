import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksFourColSteps,
  SettingsPartsFragment,
} from '@generated/types';
import lottie from 'lottie-web';
import { useEffect, useRef, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';

export default function FourColSteps({
  data,
  global,
  settings,
  site,
}: {
  data: PageBlocksFourColSteps;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [animationInstance, setAnimationInstance] = useState<any>(null);
  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    if (data?.useAnimation) {
      const loadAnimationData = async () => {
        let desktopData, mobileData;

        // Dynamically load animation data based on 'site'
        if (site === 'main-site') {
          desktopData = await import('./4colsteps_1920.json');
          mobileData = await import('./4colsteps_mobile.json');
        } else if (site === 'cosmetics') {
          desktopData = await import('./cosmetics_4colsteps_1920.json');
          mobileData = await import('./cosmetics_4colsteps_mobile.json');
        }

        // Choose appropriate data based on window width
        const selectedData =
          window.innerWidth >= 992 ? desktopData?.default : mobileData?.default;
        setAnimationData(selectedData);
      };

      loadAnimationData();
    }
  }, [site, data?.useAnimation]);

  useEffect(() => {
    if (data?.useAnimation && animationData && containerRef.current) {
      const instance = lottie.loadAnimation({
        container: containerRef.current,
        animationData: animationData,
        renderer: 'svg',
        loop: true,
        autoplay: false, // Start paused
      });
      setAnimationInstance(instance);

      return () => {
        if (instance) {
          instance.destroy(); // Clean up the animation instance
        }
      };
    }
  }, [animationData, data?.useAnimation]);

  useEffect(() => {
    if (data?.useAnimation) {
      const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            animationInstance?.play();
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, {
        threshold: 0.1, // Adjust this value to change when the animation starts
      });

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }
  }, [animationInstance, data?.useAnimation]);

  return (
    <section
      className={`section-four-col-steps lottieOuter fullwidth-container flex items-center justify-center ${spacingClasses}`}
      {...getSpacingProps(data)}
      data-tina-field={tinaField(data)}
      id={data?.id || undefined}
    >
      {data?.useAnimation ? (
        <div className="relative flex items-center justify-center">
          <div ref={containerRef} />
          {/* Render the container if useAnimation is true */}
        </div>
      ) : (
        <div className="container">
          <div className="grid contentGrid">
            <div
              className={
                'col-span-4 lg:col-span-2 xl:col-span-1 mb-4 xl:mb-0 ' +
                (data?.colOne?.colOffset ? 'mt-[160px]' : 'mt-0')
              }
            >
              <div className="heading-display">{data?.colOne?.displayText}</div>
              <h4 className="mb-4 mt-8">{data?.colOne?.colHeadline}</h4>
              <p className="mb-12">{data?.colOne?.colBody}</p>
            </div>
            <div
              className={
                'col-span-4 lg:col-span-2 xl:col-span-1 mb-4  xl:mb-0 ' +
                (data?.colTwo?.colOffset ? 'mt-[160px]' : 'mt-0')
              }
            >
              <div className="heading-display">{data?.colTwo?.displayText}</div>
              <h4 className="mb-4 mt-8">{data?.colTwo?.colHeadline}</h4>
              <p className="mb-12">{data?.colTwo?.colBody}</p>
            </div>
            <div
              className={
                'col-span-4 lg:col-span-2 xl:col-span-1  mb-4 lg:mb-0 ' +
                (data?.colThree?.colOffset ? 'mt-[160px]' : 'mt-0')
              }
            >
              <div className="heading-display">
                {data?.colThree?.displayText}
              </div>
              <h4 className="mb-4 mt-8">{data?.colThree?.colHeadline}</h4>
              <p className="mb-12">{data?.colThree?.colBody}</p>
            </div>
            <div
              className={
                'col-span-4 lg:col-span-2 xl:col-span-1 mb-0 ' +
                (data?.colFour?.colOffset ? 'mt-[160px]' : 'mt-0')
              }
            >
              <div className="heading-display">
                {data?.colFour?.displayText}
              </div>
              <h4 className="mb-4 mt-8">{data?.colFour?.colHeadline}</h4>
              <p className="mb-12">{data?.colFour?.colBody}</p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

FourColSteps.blockName = 'PageBlocksFourColSteps';
