import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksInfoImageText,
  SettingsPartsFragment,
} from '@generated/types';
import ShapesComponent from '@libs/ui/shared/shapes';
import { useEffect, useRef, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import AnyMedia, { sizes } from '../../utils/any-media';
import { SafeLink } from '../../utils/safe-link';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';
import placeholder from './info-image-text.gif';

const placeHolderUrl = `${placeholder.src}?width=${placeholder.width}&height=${placeholder.height}`;

export default function InfoImageText({
  data,
  site,
}: {
  data: PageBlocksInfoImageText;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLImageElement>(null);
  const textColRef = useRef<HTMLDivElement>(null);

  const [arrowPosition, setArrowPosition] = useState(0);
  const [isXlScreen, setIsXlScreen] = useState(false);
  const isImageLeft = data?.variant === 'Image Left - Text Right';

  const fontWeightMap: { [key: string]: string } = {
    UltraLight: 'font-extralight',
    Light: 'font-light',
    Regular: 'font-normal',
    Medium: 'font-medium',
    Bold: 'font-bold',
  };

  const headingSizeMap: { [key: string]: string } = {
    H1: 'heading-1',
    H2: 'heading-2',
    H3: 'heading-3',
    H4: 'heading-4',
    Body: 'body-text',
    Caption: 'caption-text',
  };

  const ArrowIcon = () => (
    <svg
      width="30"
      height="30"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6002 0.371094H17.6002V35.4111H19.6002V0.371094Z"
        className="fill-shapes-path"
      />
      <path
        d="M18.6002 37.0011L0.200195 18.5911L1.6102 17.1811L18.6002 34.1711L35.5902 17.1811L37.0002 18.5911L18.6002 37.0011Z"
        className="fill-shapes-path"
      />
    </svg>
  );

  const headlineWeight = fontWeightMap[data?.headline?.fontWeight || 'Light'];
  const textWeight = fontWeightMap[data?.body?.fontWeight || 'Regular'];
  const headingSize = headingSizeMap[data?.headline?.fontSize || 'H4'];
  const bodySize = headingSizeMap[data?.body?.fontSize || 'Body'];

  useEffect(() => {
    const updateScreenSize = () => {
      setIsXlScreen(window.innerWidth >= 1200);
    };

    updateScreenSize();
    window.addEventListener('resize', updateScreenSize);

    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    const arrow = arrowRef.current;
    const textCol = textColRef.current;
    if (!container || !arrow || !textCol || !isXlScreen) return;

    const handleScroll = () => {
      const containerRect = container.getBoundingClientRect();
      const textColRect = textCol.getBoundingClientRect();
      const middleOfScreen = window.innerHeight / 2;

      if (containerRect.top <= middleOfScreen && containerRect.bottom >= 0) {
        const percentageScrolled =
          (middleOfScreen - containerRect.top) /
          (middleOfScreen - containerRect.height / 2);

        const maxArrowMovement = textColRect.height - 50;
        const newArrowPosition = Math.min(
          Math.max(percentageScrolled * maxArrowMovement, 0),
          maxArrowMovement,
        );

        setArrowPosition(newArrowPosition);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isXlScreen]);

  return (
    <section
      data-tina-field={tinaField(data)}
      className={`section-info-image-text flex items-center justify-center ${spacingClasses}`}
      {...getSpacingProps(data)}
      id={data?.id || undefined}
    >
      <div ref={containerRef} className="flex container info-image-outer">
        <div className="contentGrid grid">
          <div
            className={`col-span-4 xl:col-span-2 ${isImageLeft ? 'xl:order-1' : 'xl:order-2'}`}
          >
            <div className="w-full">
              <div
                data-shadow={data?.boxShadow}
                className={`relative overflow-hidden w-full data-[shadow=true]:box-shadow-big`}
                data-tina-field={tinaField(data, 'image')}
              >
                <AnyMedia
                  className={`w-full object-cover relative flex`}
                  source={data?.image || placeHolderUrl}
                  alt={data?.mediaAlt || data?.headline?.text || ''}
                  priority={data?.isPriority || undefined}
                  quality={data?.isPriority ? 95 : undefined}
                  sizes={sizes({
                    default: '100vw',
                    lg: '50vw',
                  })}
                />
              </div>
            </div>
          </div>
          <div
            className={`col-span-4 xl:col-span-2 flex xl:items-end pt-[50px] xl:pt-[0] lg:mt-[-30px] xl:mb-[-26px] hd:mb-[-42px] ${isImageLeft ? 'xl:order-2' : 'xl:order-1'}`}
          >
            <div
              className={`text-text_color leading-normal ${headlineWeight} ${headingSize}`}
              data-tina-field={tinaField(data, 'headline')}
            >
              {data.headline?.text
                ?.split('\n')
                ?.map((t, i) => (t ? <p key={i}>{t}</p> : <br key={i} />)) ||
                `One Process,\nEndless Possibilities`}
            </div>
          </div>
          <div className="col-span-4 xl:col-span-2 xl:order-3 pt-[50px] pb-[75px] flex justify-end">
            <div data-tina-field={tinaField(data, 'shapeOptions')}>
              <ShapesComponent
                shapeOptions={data?.shapeOptions || {}}
                shapeBoxshadow={false}
                textColor="#00ff00"
              />
            </div>
          </div>
          <div className="col-span-4 xl:col-span-2 xl:order-4"></div>
          <div
            ref={textColRef}
            className="col-span-4 xl:col-span-2 xl:order-5 textCol"
          >
            <div
              className={`overflow-hidden h-full ${textWeight} ${bodySize}`}
              data-tina-field={tinaField(data, 'body')}
            >
              {data.body?.text
                ?.split('\n')
                ?.map((t, i) => (t ? <p key={i}>{t}</p> : <br key={i} />)) ||
                `Default body content goes here.`}
            </div>
          </div>
          <div className="col-span-4 xl:col-span-2 xl:order-6 readMoreCol">
            <div
              className="h-full flex flex-col justify-end items-end"
              data-tina-field={tinaField(data, 'headlineLinkButton')}
            >
              <div className="flex flex-row justify-end xl:justify-normal flex-1 mt-8 lg:mt-0">
                <SafeLink
                  href={data?.headlineLinkButton?.link}
                  className="text-primary leading-normal font-light font-['Helvetica Neue']"
                >
                  {data?.headlineLinkButton?.buttonText || 'Explore more'}
                </SafeLink>
              </div>

              <SafeLink href={data?.headlineLinkButton?.link}>
                <div
                  className="mt-4 ml-24 xl:ml-0"
                  data-tina-field={tinaField(data)}
                >
                  <span
                    className="-z-50 relative"
                    style={{
                      transform: isXlScreen
                        ? `translateY(${arrowPosition}px)`
                        : 'none',
                      transition: isXlScreen
                        ? 'transform 0.3s ease-in-out'
                        : 'none', // Smooth transition only for xl screens
                    }}
                  >
                    <ArrowIcon />
                  </span>
                </div>
              </SafeLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

InfoImageText.blockName = 'PageBlocksInfoImageText';
