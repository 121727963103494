'use client';
import { Site } from '@apps/site';
import { PageBlocksTwoColImageTwoColText } from '@generated/types';
import SubMenuSingle from '@libs/ui/utils/SubMenuSingle';
import SubmenuSingleIcon from '@libs/ui/utils/Templates/Shapes/SubmenuSingleIcon';
import {
  getFontSizeClass,
  getFontWeightClass,
  getHeadlineTag,
  HeadlineParams,
  transformToHeadlineParams,
} from '@libs/ui/utils/Templates/style';
import { useEffect, useRef, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import AnyMedia, { sizes } from '../../utils/any-media';
import { SafeLink } from '../../utils/safe-link';
import { useScroll } from '../../utils/scroll-context';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';

function getClassFromParams(params: HeadlineParams) {
  const tag = getHeadlineTag(params);
  const fontWeightClass = params.fontWeight
    ? getFontWeightClass({ fontWeight: params.fontWeight })
    : '';
  const fontSizeClass = getFontSizeClass({ fontSize: params.fontSize });

  return {
    tag,
    fontWeightClass,
    fontSizeClass,
  };
}

const MediaContainer = ({
  data,
  site,
  visibleMenu,
  setVisibleMenu,
  hidden,
}: {
  data: PageBlocksTwoColImageTwoColText;
  site: Site;
  visibleMenu: number | null;
  setVisibleMenu: (v: number | null) => void;
  hidden?: boolean;
}) => {
  return (
    <div
      className={`col-span-4 [overflow-anchor:none]  lg:col-span-2 mt-4 lg:mt-0 ${
        data?.layout === 'Image Left Text Right' ? 'lg:order-1' : 'lg:order-2'
      } ${
        data.variant === 'HalfFullscreen' && !hidden
          ? `relative lg:absolute lg:w-[50vw] lg:h-screen lg:overflow-hidden lg:top-[-50vh] lg:left-0 ${data?.layout === 'Image Right Text Left' ? 'lg:ml-[50vw]' : ''}`
          : 'relative'
      }`}
    >
      <span
        className="opacity-0 [overflow-anchor:none] data-[selected=true]:opacity-100 transition-opacity duration-500"
        data-selected={
          visibleMenu === null || !data.entries?.[visibleMenu]?.media
        }
      >
        <AnyMedia
          source={
            (data?.useEmbeddedLink ? data.embedUrl || data.media : data.media)!
          }
          alt={data?.mediaAlt || 'Two Column Layout Image'}
          data-tina-field={tinaField(data, 'media')}
          data-shadow={data?.boxShadow}
          data-hidden={hidden}
          sizes={
            data?.variant === 'HalfFullscreen'
              ? '100vw'
              : sizes({
                  default: '100vw',
                  lg: '75vw',
                })
          }
          className={`media data-[hidden=true]:lg:hidden object-cover columnImage data-[shadow=true]:box-shadow-big [&>img]:object-cover [&>img]:h-full ${
            data.variant === 'Large'
              ? 'w-full h-[350px] xl:h-[500px] hd:h-[800px] 2k:h-[1100px]'
              : data.variant === 'HalfFullscreen'
                ? 'lg:h-screen w-full h-[350px]'
                : data.variant === 'Small'
                  ? 'w-full h-[250px] xl:h-[300px] hd:h-[450px] 2k:h-[600px]'
                  : 'w-full h-[250px] xl:h-[300px] hd:h-[450px] 2k:h-[600px]'
          }`}
        />
      </span>
      {data.entries?.map(
        (entry, index) =>
          entry?.media && (
            <span
              key={index}
              data-selected={visibleMenu === index}
              className="opacity-0 [overflow-anchor:none] data-[selected=true]:opacity-100 transition-opacity duration-500 absolute top-0 left-0 right-0 bottom-0 w-full"
            >
              <AnyMedia
                source={
                  entry?.useEmbeddedLink
                    ? entry.embedUrl || entry.media
                    : entry.media
                }
                data-hidden={hidden}
                alt={entry?.mediaAlt || 'Image for: ' + entry.fieldText}
                data-tina-field={tinaField(data, 'media')}
                sizes={sizes({
                  default: '100vw',
                  lg: '50vw',
                })}
                className={`absolute data-[hidden=true]:lg:hidden top-0 left-0 right-0 bottom-0 object-cover columnImage ${
                  data.variant === 'Large'
                    ? 'h-[350px] xl:h-[500px] hd:h-[800px] 2k:h-[1100px]'
                    : data.variant === 'HalfFullscreen'
                      ? 'lg:h-screen h-[350px]'
                      : data.variant === 'Small'
                        ? 'h-[250px] xl:h-[300px] hd:h-[450px] 2k:h-[600px]'
                        : 'h-[250px] xl:h-[300px] hd:h-[450px] 2k:h-[600px]'
                }`}
              />
            </span>
          ),
      )}
    </div>
  );
};

export default function TwoColImageTwoColText({
  data,
  site,
}: {
  data: PageBlocksTwoColImageTwoColText;
  site: Site;
}) {
  const headlineParams = transformToHeadlineParams(data?.headline);
  const bodyParams = transformToHeadlineParams(data?.body);

  const { tag: HeadlineTag, fontWeightClass: headlineClass } =
    getClassFromParams(headlineParams);
  const {
    tag: BodyTag,
    fontWeightClass,
    fontSizeClass,
  } = getClassFromParams(bodyParams);
  const bodyClass = `${fontWeightClass} ${fontSizeClass}`;
  const layoutClass =
    data?.layout === 'Image Left Text Right'
      ? 'flex-col lg:flex-row'
      : 'flex-col-reverse lg:flex-row';
  const [visibleMenu, setVisibleMenu] = useState<number | null>(null); // State to track which submenu is visible

  const scrollY = useScroll();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isFullscreen =
      window?.innerHeight - (ref.current?.offsetHeight || 0) <
        window?.innerHeight / 3 || 300;
    const end = isFullscreen
      ? (ref.current?.offsetTop || 0) + window?.innerHeight / 4
      : ref.current?.offsetTop || 0;
    const start = isFullscreen
      ? end - window?.innerHeight / 2
      : end - window?.innerHeight + (ref.current?.offsetHeight || 0);

    const progress = (start - scrollY) / (start - end);
    const cappedProgress = Math.min(Math.max(progress, 0), 1);
    const indexVal =
      Math.floor((data?.entries?.length || 1) * cappedProgress) - 1;
    const index = indexVal < 0 ? null : indexVal;
    setVisibleMenu(index);
  }, [scrollY, setVisibleMenu, data?.entries?.length]);

  return (
    <section
      className={`section-twocol-imagetext w-full [overflow-anchor:none] flex items-center justify-center ${spacingClasses}`}
      {...getSpacingProps(data)}
      data-tina-field={tinaField(data)}
      id={data?.id || undefined}
      ref={ref}
    >
      {data?.media && data?.variant === 'HalfFullscreen' && (
        <div
          className={`w-0 h-0 relative hidden [overflow-anchor:none] lg:block`}
        >
          <MediaContainer
            {...{
              visibleMenu,
              setVisibleMenu,
              data,
              site,
            }}
          />
        </div>
      )}
      <div
        className={`h-auto container [overflow-anchor:none] flex ${layoutClass} items-center`}
      >
        <div className="grid contentGrid w-full [overflow-anchor:none]">
          {data?.media && (
            /*
             */
            <MediaContainer
              {...{
                visibleMenu,
                setVisibleMenu,
                data,
                site,
                hidden: data?.variant === 'HalfFullscreen',
              }}
            />
          )}

          <div
            className={`flex [overflow-anchor:none] flex-col justify-center col-span-4 lg:col-span-2 mt-8 lg:mt-0 ${data?.layout === 'Image Left Text Right' ? 'lg:order-2' : 'lg:order-1'}`}
          >
            {data?.headline?.text && (
              <div className="flex items-center justify-between mb-4">
                <HeadlineTag
                  className={`${headlineClass}`}
                  data-tina-field={tinaField(data, 'headline')}
                >
                  {data.headline?.text
                    ?.split('\n')
                    ?.map((t, i) => (t ? <p key={i}>{t}</p> : <br key={i} />))}
                </HeadlineTag>
              </div>
            )}

            {data?.body?.text && (
              <BodyTag
                className={`${bodyClass}`}
                data-tina-field={tinaField(data, 'body')}
              >
                {data.body.text
                  ?.split('\n')
                  ?.map((t, i) => (t ? <p key={i}>{t}</p> : <br key={i} />))}
              </BodyTag>
            )}

            {data?.entries && data.entries?.length > 0 && (
              <div className="col-span-4" data-tina-field={tinaField(data)}>
                <SubMenuSingle
                  data={data?.entries}
                  hideTopDots
                  {...{ visibleMenu, setVisibleMenu }}
                />
              </div>
            )}

            {data?.linkButton?.buttonText && data?.linkButton?.link && (
              <SafeLink
                href={data.linkButton.link}
                className="mt-4 py-3 text-text_color flex items-center"
                data-tina-field={tinaField(data, 'linkButton')}
              >
                <span>{data.linkButton.buttonText}</span>
                <div className="shapesOuter ml-[1rem] lg:ml-[40px]">
                  <SubmenuSingleIcon
                    width={15}
                    height={25}
                    className="text-text_color"
                  />
                </div>
              </SafeLink>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

TwoColImageTwoColText.blockName = 'PageBlocksTwoColImageTwoColText';
