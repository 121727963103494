'use client';

import * as SliderPrimitive from '@radix-ui/react-slider';
import * as React from 'react';

import { cn } from '@libs/ui/utils';
import { shapeClassName } from './shapes';

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex w-full touch-none select-none items-center pl-4 [&>span]:transition-all',
      className,
    )}
    {...props}
  >
    <SliderPrimitive.Track
      aria-label="Slider track"
      className="relative h-0.5 w-full grow overflow-hidden rounded-full bg-shade20 md:-ml-24 -z-50"
    >
      <SliderPrimitive.Range
        aria-label="Slider range"
        className="absolute h-full bg-shade20"
      />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb
      aria-label="Slider thumb"
      className={`block ${shapeClassName} rounded-full border-2 border-primary bg-primary ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50`}
    />
  </SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
