import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksBreadcrumb,
  SettingsPartsFragment,
} from '@generated/types';
import { Fragment } from 'react/jsx-runtime';
import { tinaField } from 'tinacms/dist/react';
import { SafeLink } from '../../utils/safe-link';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';

export default function Breadcrumb({
  data,
  global,
  settings,
  site,
  path,
}: {
  data: PageBlocksBreadcrumb;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
  path: string;
}) {
  const isHome = path === 'home';
  const segments = (
    isHome ? [] : path.split('/').filter((segment) => segment !== '')
  ).map((segment, index, arr) => ({
    link: segment === 'home' ? '/' : arr.slice(0, index + 1)?.join('/'),
    name: segment,
  }));

  return (
    <section
      className={`section-breadcrumb w-full capitalize container ${spacingClasses}`}
      {...getSpacingProps(data)}
      data-tina-field={tinaField(data)}
      id={data?.id || undefined}
    >
      {[
        { link: '/', name: 'home' },
        ...(data.overrideBreadcrumb && data.segments
          ? data.segments
          : segments),
      ].map((segment, index) => {
        return (
          <Fragment key={index}>
            {index > 0 && <span className="mx-2">/</span>}
            <SafeLink href={segment?.link}>{segment?.name}</SafeLink>
          </Fragment>
        );
      })}
    </section>
  );
}
Breadcrumb.blockName = 'PageBlocksBreadcrumb';
