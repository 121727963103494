import { Site } from '@apps/site';
import { PageBlocksTeaserTextLarge } from '@generated/types';
import { convertRichTextToHTML, getRichText } from '@libs/ui/utils';
import { tinaField } from 'tinacms/dist/react';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';

export default function TeaserTextLarge({
  data,
  site,
}: {
  data: PageBlocksTeaserTextLarge;
  site: Site;
}) {
  const richText = data.richText || getRichText('No content available');

  const richFormattedText = convertRichTextToHTML(richText);

  return (
    <section
      className={`section-teaser-text-large container flex items-center justify-center ${spacingClasses}`}
      {...getSpacingProps(data)}
      data-tina-field={tinaField(data)}
      id={data?.id || undefined}
    >
      <div className="grid contentGrid">
        {richFormattedText ? (
          <div
            data-tina-field={tinaField(data, 'richText')}
            className="text-left col-span-4 p-4"
            dangerouslySetInnerHTML={{ __html: richFormattedText }}
          ></div>
        ) : (
          'No content available'
        )}
      </div>
    </section>
  );
}

TeaserTextLarge.blockName = 'PageBlocksTeaserTextLarge';
