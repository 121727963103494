import type { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageQuery,
  SettingsPartsFragment,
} from '@generated/types';
import { Fragment } from 'react';
import { TrimmedArticle } from '../Page';
import Accordion from './Accordion';
import AnimationFactory from './AnimationFactory';
import AnimationText from './AnimationText';
import AnimationVennDiagram from './AnimationVennDiagram';
import Breadcrumb from './Breadcrumb';
import CategorySlider from './CategorySlider';
import CheckList from './CheckList';
import ColImageText from './ColImageText';
import ContactForm from './ContactForm';
import { HubSpotFormResponse } from './ContactForm/types';
import DownloadList from './DownloadList';
import ExploreButton from './ExploreButton';
import FourColImages from './FourColImages';
import FourColSteps from './FourColSteps';
import FullImageVideo from './FullImageVideo';
import Gallery from './Gallery';
import HeroSection from './HeroSection';
import HistoryText from './HistoryText';
import HorizontalCards from './HorizontalCards';
import InfoImageText from './InfoImageText';
import InfoText from './InfoText';
import ListInfo from './ListInfo';
import MediumTeaserText from './MediumTeaserText';
import NewsCardsBlogPosts from './NewsCardsBlogPosts';
import NewsListBlogPosts from './NewsListBlogPosts';
import Spacing from './Spacing';
import TeamSlider from './TeamSlider';
import TeaserTextLarge from './TeaserTextLarge';
import ThreeColImageOneColText from './ThreeColImageOneColText';
import TwoColImageTwoColText from './TwoColImageTwoColText';

const templates = [
  Accordion,
  AnimationText,
  Breadcrumb,
  CategorySlider,
  CheckList,
  ColImageText,
  ContactForm,
  DownloadList,
  ExploreButton,
  FourColImages,
  FourColSteps,
  FullImageVideo,
  Gallery,
  HeroSection,
  HistoryText,
  HorizontalCards,
  InfoImageText,
  InfoText,
  ListInfo,
  MediumTeaserText,
  NewsCardsBlogPosts,
  NewsListBlogPosts,
  TeamSlider,
  TeaserTextLarge,
  ThreeColImageOneColText,
  TwoColImageTwoColText,
  AnimationFactory,
  AnimationVennDiagram,
  Spacing,
];

export const Blocks = (props: {
  blocks?: PageQuery['page']['blocks'];
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
  path: string;
  articles?: TrimmedArticle[] | null;
  forms: Record<string, HubSpotFormResponse>;
  title?: string;
}) => {
  return (
    <Fragment>
      {(props.blocks || []).map(function (block, i) {
        const Component = templates.find(
          (v) => v.blockName === block?.__typename,
        );

        if (block?.__typename && Component) {
          return (
            <Component
              data={block as any}
              key={i + block?.__typename}
              site={props?.site}
              {...{ path: props.path }}
              {...(block?.__typename === 'PageBlocksNewsCardsBlogPosts' ||
              block?.__typename === 'PageBlocksNewsListBlogPosts'
                ? { articles: props.articles }
                : {})}
              {...(block?.__typename === 'PageBlocksContactForm'
                ? { forms: props.forms, title: props.title, path: props.path }
                : {})}
            />
          );
        } else {
          return <div key={i}>Block not found {block?.__typename}</div>;
        }
      })}
    </Fragment>
  );
};
