import { Site } from '@apps/site';
import { PageBlocksColImageText } from '@generated/types';
import { FontSize, FontWeight } from '@libs/ui/utils/enums';
import { SafeLink } from '@libs/ui/utils/safe-link';
import SubmenuSingleIcon from '@libs/ui/utils/Templates/Shapes/SubmenuSingleIcon';
import {
  getFontSizeClass,
  getFontWeightClass,
  getHeadlineTag,
  HeadlineParams,
  isFontSize,
  isFontWeight,
  transformToHeadlineParams,
} from '@libs/ui/utils/Templates/style';
import { tinaField } from 'tinacms/dist/react';
import AnyMedia, { sizes } from '../../utils/any-media';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';

function getClassFromParams(params: HeadlineParams) {
  const tag = getHeadlineTag(params);
  const fontWeightClass = params.fontWeight
    ? getFontWeightClass({ fontWeight: params.fontWeight })
    : '';
  const fontSizeClass = getFontSizeClass({ fontSize: params.fontSize });

  return {
    tag,
    fontWeightClass,
    fontSizeClass,
  };
}

export default function ColImageText({
  data,
  site,
}: {
  data: PageBlocksColImageText;
  site: Site;
}) {
  const headlineParams = transformToHeadlineParams(data?.headline);
  const { tag: HeadlineTag, fontWeightClass } =
    getClassFromParams(headlineParams);

  const gridCols =
    data?.variant === '4 Columns'
      ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'
      : 'grid-cols-1 sm:grid-cols-2';

  return (
    <section
      className={`section col-image-text container w-full items-start flex items-center justify-center ${spacingClasses}`}
      {...getSpacingProps(data)}
      data-tina-field={tinaField(data)}
      id={data?.id || undefined}
    >
      {/* Render headline using correct headline structure */}
      {data?.headline?.text && (
        <HeadlineTag
          className={`${fontWeightClass} text-left mb-20`}
          data-tina-field={tinaField(data, 'headline')}
        >
          {data.headline.text}
        </HeadlineTag>
      )}

      {/* Apply the responsive grid classes */}
      <div className={`grid contentGrid w-full gap-4 ${gridCols}`}>
        {data.entries?.map((entry, index) => {
          const entryHeadlineParams = {
            fontSize: isFontSize(data?.entryStyle?.headline?.fontSize ?? '')
              ? (data?.entryStyle?.headline?.fontSize as FontSize)
              : null,
            fontWeight: isFontWeight(
              data?.entryStyle?.headline?.fontWeight ?? '',
            )
              ? (data?.entryStyle?.headline?.fontWeight as FontWeight)
              : null,
          };

          const entryBodyParams = {
            fontSize: isFontSize(data?.entryStyle?.body?.fontSize ?? '')
              ? (data?.entryStyle?.body?.fontSize as FontSize)
              : null,
            fontWeight: isFontWeight(data?.entryStyle?.body?.fontWeight ?? '')
              ? (data?.entryStyle?.body?.fontWeight as FontWeight)
              : null,
          };

          const EntryHeadlineTag = getHeadlineTag(entryHeadlineParams);
          const headlineClass = `${getClassFromParams(entryHeadlineParams).fontWeightClass} ${getClassFromParams(entryHeadlineParams).fontSizeClass}`;
          const bodyClass = `${getClassFromParams(entryBodyParams).fontWeightClass} ${getClassFromParams(entryBodyParams).fontSizeClass}`;

          return (
            <div
              className={`col-span-4 lg:col-span-2 xl:col-span-1 data-[hide-on-mobile=true]:hidden data-[hide-on-mobile=true]:lg:block`}
              key={`list-entry-image-text#${index}`}
              data-tina-field={tinaField(data, 'entries')}
              data-hide-on-mobile={entry?.hideOnMobile}
            >
              {entry?.media && (
                <div
                  data-shadow={data?.boxShadow}
                  data-aspect={data?.mediaAspectRatio}
                  className={`relative data-[aspect=3:2]:aspect-[3/2] data-[aspect=2:3]:aspect-[2/3] data-[aspect=2:4]:aspect-[2/4] aspect-[1/1] overflow-hidden data-[shadow=true]:box-shadow-big`}
                  data-tina-field={tinaField(entry, 'media')}
                >
                  <AnyMedia
                    source={
                      entry.useEmbeddedLink
                        ? entry.embedUrl || entry.media
                        : entry.media
                    }
                    sizes={sizes({
                      default: '100vw',
                      lg: data?.variant === '4 Columns' ? '25vw' : '50vw',
                    })}
                    alt={entry?.mediaAlt || `Entry Media ${index + 1}`}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
              )}

              {entry?.colHeadline && (
                <EntryHeadlineTag
                  className={`block mb-4 mt-[15px] sm:mt-5 ${EntryHeadlineTag === 'span' ? 'block' : ''} ${headlineClass}`}
                  data-tina-field={tinaField(entry, 'colHeadline')}
                >
                  {entry.colHeadline}
                </EntryHeadlineTag>
              )}
              {entry?.colBody && (
                <p
                  className={`${bodyClass}`}
                  data-tina-field={tinaField(entry, 'colBody')}
                >
                  {entry.colBody}
                </p>
              )}
              {entry?.linkName && entry?.linkTarget && (
                <div className="flex items-center mt-4">
                  <SubmenuSingleIcon
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '10px',
                    }}
                  />
                  <SafeLink
                    href={entry.linkTarget}
                    data-tina-field={tinaField(entry, 'linkTarget')}
                  >
                    {entry.linkName}
                  </SafeLink>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
}

ColImageText.blockName = 'PageBlocksColImageText';
