import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksFourColImages,
  SettingsPartsFragment,
} from '@generated/types';
import { tinaField } from 'tinacms/dist/react';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';

export default function FourColImages({
  data,
  global,
  settings,
  site,
}: {
  data: PageBlocksFourColImages;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  return (
    <section
      className={`section-four-col-img w-full h-20 border rounded p-4 flex items-center justify-center ${spacingClasses}`}
      {...getSpacingProps(data)}
      data-tina-field={tinaField(data)}
      id={data?.id || undefined}
    >
      FourColImages
    </section>
  );
}
FourColImages.blockName = 'PageBlocksFourColImages';
