import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksSpacing,
  SettingsPartsFragment,
} from '@generated/types';
import { assignUnits } from '../../utils';

export default function Spacing({
  data,
}: {
  data: PageBlocksSpacing;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  const { xs, sm, md, lg, xl, hd, twoK } = data;

  return (
    <section className={`section-space`} id={data?.id || undefined}>
      <div
        className="h-[var(--spacing-block-xs)] sm:h-[var(--spacing-block-sm)] md:h-[var(--spacing-block-md)] lg:h-[var(--spacing-block-lg)] xl:h-[var(--spacing-block-xl)] hd:h-[var(--spacing-block-hd)] 2k:h-[var(--spacing-block-2k)]"
        style={
          {
            ...{
              '--spacing-block-xs': assignUnits(xs) || '0px', // Start with 0 if no xs is defined
              '--spacing-block-sm':
                assignUnits(sm) || 'var(--spacing-block-xs)',
              '--spacing-block-md':
                assignUnits(md) || 'var(--spacing-block-sm)',
              '--spacing-block-lg':
                assignUnits(lg) || 'var(--spacing-block-md)',
              '--spacing-block-xl':
                assignUnits(xl) || 'var(--spacing-block-lg)',
              '--spacing-block-hd':
                assignUnits(hd) || 'var(--spacing-block-xl)',
              '--spacing-block-2k':
                assignUnits(twoK) || 'var(--spacing-block-hd)',
            },
          } as React.CSSProperties
        }
      />
    </section>
  );
}

Spacing.blockName = 'PageBlocksSpacing';
