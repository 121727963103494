import { Site } from '@apps/site';
import {
  Maybe,
  PageBlocksGallery,
  PageBlocksGalleryImageEntries,
} from '@generated/types';
import ShapesComponent from '@libs/ui/shared/shapes';
import SubmenuSingleIcon from '@libs/ui/utils/Templates/Shapes/SubmenuSingleIcon';
import {
  getFontSizeClass,
  getFontWeightClass,
  getFontWeightClassFromData,
  getHeadlineTagFromData,
  transformToHeadlineParams,
} from '@libs/ui/utils/Templates/style';
import { SafeLink } from '@libs/ui/utils/safe-link';
import React from 'react';
import { tinaField } from 'tinacms/dist/react';
import AnyMedia, { sizes } from '../../utils/any-media';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';

export default function Gallery({
  data,
  site,
}: {
  data: PageBlocksGallery;
  site: Site;
}) {
  return (
    <section
      className={`section-gallery w-full flex flex-col items-center justify-center ${spacingClasses}`}
      {...getSpacingProps(data)}
      data-tina-field={tinaField(data)}
      id={data?.id || undefined}
    >
      <GalleryContent {...{ data, site }} />
    </section>
  );
}

const ImageEntry = ({
  entry,
  i,
  data,
  extraClasses,
}: {
  entry: Maybe<PageBlocksGalleryImageEntries>;
  i: number;
  data: PageBlocksGallery;
  extraClasses?: string;
}) => {
  if (entry && entry.media) {
    return (
      <SafeLink
        data-shadow={data?.boxShadow}
        href={entry?.linkTarget}
        data-link={!!entry?.linkTarget}
        data-show-mobile={entry.showImageOnMobile}
        className={`col-span-4 lg:col-span-2 relative data-[show-mobile=false]:hidden data-[show-mobile=false]:lg:block xl:col-span-1 w-full data-[link=true]:hover:translate-y-1 transition-transform duration-300 h-auto data-[link=true]:cursor-pointer ${extraClasses || ''} data-[shadow=true]:box-shadow-big`}
        key={`galleryEntry#${i}`}
        data-tina-field={tinaField(entry)}
        target="_blank"
      >
        <AnyMedia
          source={entry.media}
          alt={entry.mediaAlt || `Gallery Image ${i + 1}`}
          className={`w-full relative object-cover ${data.variant === 'Image 3:2' ? 'aspect-[3/2]' : 'aspect-square'}`}
          sizes={sizes({ lg: '50vw', xl: '25vw', default: '100vw' })}
        />
      </SafeLink>
    );
  }
};

const TextBlock = ({
  data,
  className,
}: {
  data: PageBlocksGallery;
  className?: string;
}) => {
  const HeadlineTag = getHeadlineTagFromData(data?.headline);
  const headlineParams = transformToHeadlineParams(data?.headline);
  const fontWeightClass = getFontWeightClassFromData(data?.headline);
  const headlineFontSizesClass = getFontSizeClass(headlineParams);
  const bodyParams = transformToHeadlineParams(data?.body);
  const bodyFontWeightClass = getFontWeightClass(bodyParams);
  const bodyFontSizesClass = getFontSizeClass(bodyParams);

  return (
    <div
      key={`galleryEntry#textBlock`}
      className={`col-span-4 lg:col-span-2 xl:col-span-1 w-full pb-[40px] mb-[20px] lg:mb-0 lg:pb-0 ${className}`}
      data-tina-field={tinaField(data)}
    >
      {data?.headline?.text && (
        <HeadlineTag
          className={`${fontWeightClass} ${headlineFontSizesClass} pb-[20px]`}
          data-tina-field={tinaField(data.headline, 'text')}
        >
          {data?.headline?.text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          )) || ``}
        </HeadlineTag>
      )}
      {data?.body?.text && (
        <p
          className={`${bodyFontWeightClass} ${bodyFontSizesClass}`}
          data-tina-field={tinaField(data.body, 'text')}
        >
          {data?.body.text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          )) || ``}
        </p>
      )}
      {data?.linkName && data?.linkTarget && (
        <div className="flex items-center mt-4">
          <SubmenuSingleIcon
            style={{
              width: '20px',
              height: '20px',
              marginRight: '10px',
            }}
          />
          <SafeLink
            href={data.linkTarget}
            data-tina-field={tinaField(data, 'linkTarget')}
          >
            {data.linkName}
          </SafeLink>
        </div>
      )}
    </div>
  );
};

function GalleryContent({
  data,
  site,
}: {
  data: PageBlocksGallery;
  site: Site;
}) {
  // Check if both headline and body are empty
  if (!data?.headline?.text && !data?.body?.text) {
    return (
      <div className="container">
        <div
          className={`contentGrid grid pb-[50px] gap-y-4 xl:gap-y-custom-xl-gap hd:gap-y-custom-hd-gap 2k:gap-y-custom-2k-gap`}
        >
          {data.imageEntries?.map(
            (entry: Maybe<PageBlocksGalleryImageEntries>, i: number) => (
              <ImageEntry data={data} entry={entry} i={i} key={i} />
            ),
          )}
        </div>

        {/* Shapes */}
        <div
          className="contentGrid grid gap-y-4 xl:gap-y-custom-xl-gap hd:gap-y-custom-hd-gap 2k:gap-y-custom-2k-gap "
          data-tina-field={tinaField(data, 'shapeOptions')}
        >
          <div className="flex justify-end lg:col-start-2 col-start-4">
            <ShapesComponent shapeOptions={data?.shapeOptions || {}} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div
        className={`contentGrid grid pb-[50px] gap-y-[20px] lg:gap-y-[40px]  xl:gap-y-custom-xl-gap hd:gap-y-custom-hd-gap 2k:gap-y-custom-2k-gap `}
      >
        <TextBlock data={data} className="block lg:hidden" />
        {data.imageEntries
          ?.slice(0, 3)
          ?.map((e, i) => (
            <ImageEntry
              data={data}
              entry={e}
              i={i}
              key={i}
              extraClasses="mb-[20px] lg:mb-0 relative"
            />
          ))}
        <TextBlock data={data} className="hidden lg:block" />
        {data.imageEntries
          ?.slice(3)
          ?.map((e, i) => (
            <ImageEntry
              data={data}
              entry={e}
              i={i}
              key={i}
              extraClasses="mb-[20px] lg:mb-0 relative"
            />
          ))}
      </div>

      {/* Shapes */}
      <div
        className="contentGrid grid gap-y-4 xl:gap-y-custom-xl-gap hd:gap-y-custom-hd-gap 2k:gap-y-custom-2k-gap !z-50"
        data-tina-field={tinaField(data, 'shapeOptions')}
      >
        <div className="flex justify-end lg:col-start-2 col-start-4 !z-20">
          <ShapesComponent shapeOptions={data?.shapeOptions || {}} />
        </div>
      </div>
    </div>
  );
}

Gallery.blockName = 'PageBlocksGallery';
