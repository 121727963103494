'use client';
import { Site } from '@apps/site';
import type client from '@generated/client';
import { Fragment } from 'react';
import { useTina } from 'tinacms/dist/react';
import { Blocks } from '../Blocks';
import { HubSpotFormResponse } from '../Blocks/ContactForm/types';
import { Layout } from '../Layout';
export type TrimmedArticle = {
  createdAt?: string | null;
  relativePath: string;
  id: string;
  image?: string | null;
  imageAlt?: string | null;
  title?: string | null;
  categories?: (string | null)[] | null | undefined;
};

export const Page = ({
  response,
  path,
  site,
  articles,
  forms,
}: {
  response: Awaited<ReturnType<typeof client.queries.pageQuery>>;
  path: string;
  site: Site;
  articles?: TrimmedArticle[] | null;
  forms: Record<string, HubSpotFormResponse>;
}) => {
  const { data } = useTina(response);

  return (
    <Fragment>
      {/* HEADER GOES HERE */}
      <Layout
        global={data?.global}
        settings={data?.settings}
        pageId={data?.page?.id}
        site={site}
        data={data?.page}
        path={path}
      >
        {data?.page && (
          <Blocks
            {...data?.page}
            settings={response.data.settings}
            global={response.data.global}
            site={site}
            {...{ path, articles, forms }}
          />
        )}
      </Layout>
    </Fragment>
  );
};
