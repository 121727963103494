// ContactForm.tsx
'use client';

import { useEffect, useState, useTransition } from 'react';
import { useFormState } from 'react-dom';

import { FieldValues, SubmitHandler } from 'react-hook-form';

// import HubSpotForm, { FieldGroup } from '@ez-digital/react-hubspot-hook-form';
import '@ez-digital/react-hubspot-hook-form/style';
import HubSpotForm, { FieldGroup } from './CustomForm';

import { Site } from '@apps/site';
import { PageBlocksContactForm } from '@generated/types';
import type { Shape } from '@libs/ui/utils/enums';
import { tinaField } from 'tinacms/dist/react';
import {
  getFontWeightClass,
  getHeadlineTagFromData,
  transformToHeadlineParams,
} from '../../utils/Templates/style';
import { ContactFormSubmit } from './actions';
import { State } from './types';
import { getHubspotCookie } from './utils';

const ContactFormBase = ({
  fieldGroups,
  submitButtonText,
  contactFormId,
  site,
  data,
  title,
  path,
}: {
  fieldGroups: FieldGroup[];
  submitButtonText: string;
  contactFormId: string;
  shapesOverrides?: Shape[];
  data: PageBlocksContactForm;
  title?: string;
  path?: string;
  site: Site;
}) => {
  const [isSubmitting, startTransition] = useTransition();
  const [timestamp, setTimestamp] = useState('');
  const [honeypot, setHoneypot] = useState('');
  const ContactFormSubmitWithProps = ContactFormSubmit.bind(
    null,
    contactFormId,
  );

  const [message, formAction] = useFormState<State, FieldValues>(
    ContactFormSubmitWithProps,
    null,
  );

  useEffect(() => {
    if (message?.status === 'success') {
      console.log('form submitted successfully');
    }
    // Set a unique token and timestamp when the component mounts
    const token = Date.now().toString();
    setTimestamp(token);
  }, [message]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    const origin =
      site === 'main-site'
        ? 'www.optindustries.com'
        : 'www.cosmetics.optindustries.com';
    const hutk = getHubspotCookie(); // Retrieve the hubspotutk cookie
    // Add hidden field values
    data.honeypot = honeypot;
    data.timestamp = timestamp;
    data.hutk = hutk;
    data.pageUrl = `${origin}${path?.startsWith('/') ? '' : '/'}${path}`;
    data.pageName = title;
    startTransition(() => {
      formAction(data);
    });
  };

  const headlineParams = transformToHeadlineParams(data?.headline);
  const HeadlineTag = getHeadlineTagFromData(headlineParams);
  const fontWeightClass = getFontWeightClass(headlineParams);
  const bodyParams = transformToHeadlineParams(data?.body);
  const bodyFontWeightClass = getFontWeightClass(bodyParams);
  return (
    <div className="w-full contentGrid !grid-cols-2 lg:!grid-cols-4">
      <div className="flex flex-col col-span-2 lg:col-span-1 space-y-4">
        {/* Headline */}
        {data?.headline?.text && (
          <HeadlineTag
            className={fontWeightClass}
            data-tina-field={tinaField(data, 'headline')}
          >
            {data.headline.text}
          </HeadlineTag>
        )}
        {data?.body?.text && (
          <p
            className={bodyFontWeightClass}
            data-tina-field={tinaField(data, 'body')}
          >
            {data.body.text?.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </p>
        )}
      </div>
      <div className="hidden lg:block lg:col-span-1"></div>
      <div className="col-span-2 mt-8 lg:mt-0">
        <HubSpotForm
          fieldGroups={fieldGroups}
          submitButtonText={submitButtonText}
          data={data}
          onSubmit={onSubmit}
          fieldClassName="text-primary-foreground"
          fieldContolClassName=""
          isSubmitting={isSubmitting}
          isSubmitted={message?.status === 'success'}
          successMessage="The form has been submitted successfully."
          errorMessage={
            message?.status === 'error' ? message.message : undefined
          }
        >
          {/* Hidden honeypot field */}
          <input
            type="text"
            name="honeypot"
            value={honeypot}
            onChange={(e) => setHoneypot(e.target.value)}
            style={{ display: 'none' }}
          />
          {/* Hidden timestamp token field */}
          <input type="hidden" name="timestamp" value={timestamp} />
        </HubSpotForm>
      </div>
    </div>
  );
};

export default ContactFormBase;
