import { Site } from '@apps/site';
import { PageBlocksExploreButton } from '@generated/types';

import ShapesComponent from '@libs/ui/shared/shapes';
import SubmenuSingleIcon from '@libs/ui/utils/Templates/Shapes/SubmenuSingleIcon';
import { tinaField } from 'tinacms/dist/react';
import { SafeLink } from '../../utils/safe-link';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';

export default function ExploreButton({
  data,
  site,
}: {
  data: PageBlocksExploreButton;
  site: Site;
}) {
  return (
    <section
      data-tina-field={tinaField(data)}
      className={`section-explore-button w-full flex items-center justify-center ${spacingClasses}`}
      {...getSpacingProps(data)}
      id={data?.id || undefined}
    >
      <div className="container">
        <div className="contentGrid grid">
          <div className="col-span-4 lg:col-span-2">
            <SafeLink
              href={data.target}
              className="flex w-full justify-between items-center text-text_color explore-button  heading-3 light bt-0 pb-0 xl:h-[100px] bg:background hover:bg-shade30 transition-colors duration-500 ease-in-out"
              data-tina-field={tinaField(data, 'buttonText')}
            >
              <div className="flex items-center">
                <span>{data.buttonText || 'Explore'} </span>
                <div className="shapesOuter ml-[20px] lg:ml-[80px]">
                  <SubmenuSingleIcon className="h-[24px] w-[16px] md:h-[24px] md:w-[16px] xl:h-[22px] xl:w-[13px] text-text_color" />
                </div>
              </div>

              <div data-tina-field={tinaField(data, 'shapeOptions')}>
                <div className="!z-20">
                  <ShapesComponent shapeOptions={data?.shapeOptions || {}} />
                </div>
              </div>
            </SafeLink>
          </div>
        </div>
      </div>
    </section>
  );
}

ExploreButton.blockName = 'PageBlocksExploreButton';
