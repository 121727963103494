import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksTeamSlider,
  SettingsPartsFragment,
} from '@generated/types';
import { tinaField } from 'tinacms/dist/react';
import AnyMedia, { sizes } from '../../utils/any-media';
import { getSpacingProps, spacingClasses } from '../../utils/spacing';
export default function TeamSlider({
  data,
  site,
}: {
  data: PageBlocksTeamSlider;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  if (data?.entries) {
    let entries = data.entries;

    return (
      <section
        className={`section-team-slider container contentGrid grid w-full rounded p-4 items-center justify-center relative ${spacingClasses}`}
        {...getSpacingProps(data)}
        data-tina-field={tinaField(data)}
        id={data?.id || undefined}
      >
        {entries.map((entry, index) => {
          if (entry) {
            return (
              <div key={`team-slider#${index}`}>
                <AnyMedia
                  className="relative aspect-square col-span-1"
                  source={entry.image ?? ''}
                  alt={entry.mediaAlt ?? ''}
                  sizes={sizes({
                    default: '100vw',
                    lg: '25vw',
                  })}
                />
                <h3>{entry.entryHeadline}</h3>
                <p>{entry.entryBody}</p>
              </div>
            );
          }
          return null;
        })}
      </section>
    );
  }
}
TeamSlider.blockName = 'PageBlocksTeamSlider';
